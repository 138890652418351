/* Start by resetting some styles for the header elements */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: #529Bc7;

    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    z-index: 10;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; /* Stick to the top of the viewport */
    overflow: hidden;
    transition: 0.5s ease-in-out;
  }

  /* Additional styles for when the header becomes sticky */
  .sticky {
    background: #529Bc7; /* add transparency to the background color */
    box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Optionally add more shadow for sticky header */
    transition: 0.5s ease-in-out;
  }

  .logo-container-header {
    display: flex;
    align-items: center;
  }
  
  
  .logo-container {
    display: flex;
    align-items: center;
    /* Add some padding if needed */
  }
  
  .logo-link {
    display: flex;
    align-items: center;
  }
  
  .logo-img-header {
    height: 120px; /* Fixed height for the logo */
    width: auto;
  }
  
  .logo-text {
    display: flex; /* Use flex here to keep inline with the logo */
    align-items: center; /* Align vertically */
    white-space: nowrap; /* Prevent wrapping */
    margin-left: 10px;
    font-size: 1.5rem; /* Larger font size */
  }

  .logo-link p,
  .logo-text p {
    margin: 0;
    display: inline-block; /* This ensures the text is in line with the logo */
  }

  .logo-text p {
    font-size: 2rem;
    color: white;
  }
  
  .nav ul {
    list-style: none;
    display: flex;
    align-items: center;
  }
  
  .nav ul li {
    margin: 0 15px;
  }
  
  .nav ul li a {
    text-decoration: none;
    color: #ecf0f1; /* Light color for the text */
    font-size: 1rem;
    transition: color 0.3s ease; /* Smooth transition for color */
  }

  .nav ul li a {
    text-decoration: none;
    color: #fff; /* Example text color */
    padding: 10px; /* Add padding for larger click area */
    position: relative; /* Needed to position the pseudo-elements */
    transition: color 0.3s ease; /* Smooth transition for color */
  }
  
  /* Hover effect for nav links */
  .nav ul li a:hover,
  .nav ul li a:focus { /* Also apply to focus for accessibility */
    color: #00aaff; /* Example hover color */
  }
  
  /* Underline effect on hover */
  .nav ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #00aaff; /* Example underline color */
    transition: width 0.3s;
    position: absolute;
    bottom: -5px; /* Spacing under the link */
    left: 0;
  }
  
  /* Extend the underline on hover */
  .nav ul li a:hover::after,
  .nav ul li a:focus::after { /* Also apply to focus for accessibility */
    width: 100%;
  }
  
  .close-btn {
    display: none; /* Hide close button by default */
    color: #fff;
    font-size: 2rem;
  }
  
  .menu-icon {
    display: none;
    cursor: pointer;
  }
  
  .menu-icon div {
    width: 30px;
    height: 3px;
    background-color: #ecf0f1;
    margin: 6px 0;
    transition: transform 0.3s ease; /* Smooth transition for hamburger icon animation */
  }
  
  @media (max-width: 768px) {
    .logo-img-header {
      height: 80px;
    }

    .logo-text p {
      font-size: 1.2rem;
    }
  }

  /*
  @media (max-width: 768px) {
    .nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transform: translateX(-100%);
      transition: transform 0.3s ease-out;
    }

    .nav.open {
      transform: translateX(0);
    }

    .nav ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .nav ul li {
        width: 100%;
        margin: 10px 0;
    }
  
    .menu-icon {
      display: block;
    }

    .close-btn {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
      }

    .header::after {
      z-index: -1;
    }

    .logo-img-header {
      height: 80px;
    }
  
    .nav ul li a {
      font-size: 2rem;
    }
  }
  
  */