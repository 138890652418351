
  body {
    background-color: grey;
  }
  
  .landContainer {
    margin-top: 5vw;
  }

  .carousel {
    width: 100%;
    margin-bottom: 20px; /* Space below the carousel */
    position: relative;
  }
  
  .carousel img {
    width: 10vw;
    height: auto; /* Maintain aspect ratio */
    border-bottom: 4px solid #FFD500; /* Accent color strip from the style guide */
    padding-bottom: 5vw;
  }

  .slide div,
  .selected div {
    margin-left: auto;
    margin-right: auto;
    width: 10vw;
  }
  
  main {
    text-align: center; /* Center the text for the landing page */
    padding: 20px;
  }
  
  h1 {
    color: #214052; /* Dark Blue for primary text */
    font-family: 'Hiragino Kaku Gothic Std', sans-serif;
    font-size: 2rem; /* A comfortable font size for headings */
    margin: 40px 0 20px; /* Space around the heading */
  }
  
  p {
    color: #529BC7; /* AniThera Blue for secondary text */
    font-size: 2rem; /* Regular font size for body text */
  }
  
  /* Styling for the trademark symbols to align them with the baseline of text */

  
  /* Responsive styles for the carousel and text */
  @media (max-width: 768px) {
    h1 {
      font-size: 1.5rem;
    }
  
    p {
      font-size: 0.9rem;
    }
  
    .carousel img {
      height: auto; /* Adjust height for mobile to maintain aspect ratio */
    }
  }